* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}

:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

strong {
  font-weight: bold;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

figure {
  margin: 0 0 16px;
  display: inline-block;
}
